import React from 'react';
import { CloseIcon, Container, Content, IconContainer, Title, TitleContainer } from './Banner-styles';
import { AiOutlineClose } from 'react-icons/ai';
import { MdOutlineError } from 'react-icons/md';
import { FaInfoCircle } from 'react-icons/fa';
import { LocaleObject } from '../../types';
import { LocalizedText } from '../LocalizedText';
import { PiWarningFill } from 'react-icons/pi';
import { FaCheckCircle } from 'react-icons/fa';

export enum BannerAppearance {
    ERROR = 'error',
    WARNING = 'warning',
    SUCCESS = 'success',
    INFO = 'info',
}

const Icons = {
    [BannerAppearance.ERROR]: MdOutlineError,
    [BannerAppearance.WARNING]: PiWarningFill,
    [BannerAppearance.INFO]: FaInfoCircle,
    [BannerAppearance.SUCCESS]: FaCheckCircle,
};

type Props = {
    appearance: BannerAppearance;
    children: React.ReactNode;
    showIcon?: boolean;
    title?: LocaleObject;
    onClick?: () => void;
    closeBanner: () => void;
};

const Banner = ({ appearance, children, closeBanner, title, onClick, showIcon = false }: Props) => {
    const renderIcon = () => {
        const Icon = Icons[appearance];
        return (
            <IconContainer>
                <Icon />
            </IconContainer>
        );
    };

    const onClickHandler = () => {
        if (typeof onClick === 'function') {
            onClick();
        }
    };

    return (
        <Container
            appearance={appearance}
            hasTitle={title != null}
            onClick={onClickHandler}
            isClickable={typeof onClick === 'function'}
        >
            <TitleContainer>
                {(showIcon === true || title != null) && renderIcon()}
                {title != null && (
                    <Title>
                        <LocalizedText text={title} />
                    </Title>
                )}
            </TitleContainer>

            <Content className="banner__content">{children}</Content>

            <CloseIcon
                onClick={(e) => {
                    e.stopPropagation();
                    closeBanner();
                }}
                className="banner__close-icon"
            >
                <AiOutlineClose />
            </CloseIcon>
        </Container>
    );
};

export { Banner };
