export const box = {
    spacing: {
        xxxl: '10rem',
        xxl: '5rem',
        xl: '3rem',
        lg: '2rem',
        md: '1.6rem',
        sm: '1rem',
        xs: '.8rem',
        xxs: '.5rem',
    },
    shadow: {
        main: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        secondary: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    },
};
