import React from 'react';
import { useRoutes } from 'react-router-dom';
import { DashboardPage, DashboardSubscribersPage, HomePage, LoginPage } from '../pages';
import { AuthenticatedRoute, UnauthenticatedRoute } from './components';
import { Routes } from './dictionary';

const AppRoutes = () =>
    useRoutes([
        {
            element: <HomePage />,
            path: '*',
        },
        {
            element: <UnauthenticatedRoute />,
            children: [
                {
                    path: Routes.LOGIN,
                    element: <LoginPage />,
                },
            ],
        },
        {
            element: <AuthenticatedRoute />,
            children: [
                {
                    path: Routes.DASHBOARD,
                    element: <DashboardPage />,
                    children: [
                        {
                            path: '',
                            element: <DashboardSubscribersPage />,
                        },
                    ],
                },
            ],
        },
    ]);

export { AppRoutes };
