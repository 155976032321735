import React from 'react';
import { HomeSection } from '../../components';
import {
    Container,
    Content,
    ContentTitle,
    Cards,
    Card,
    CardContent,
    CardContentTitle,
    CardContentText,
    CardContentList,
    CardContentListItem,
    CardCta,
    Image,
    ImageContainer,
} from './HomePerks-styles';
import { PrimaryButton, SecondaryButton } from '../../../../components';
import { HomeSectionsDictionary } from '../../Home-types';
import { useTheme } from 'styled-components';

type Props = {
    goToNewsLetter: () => void;
};

const cards = [
    {
        title: 'Étudiants',
        text: 'Des missions ponctuelles, des avantages concrets',
        list: [
            'Missions ponctuelles et flexibles',
            'Travail rémunéré sans engagement',
            ' Expérience professionnelle valorisante',
            'Opportunité de développer leur réseau',
            'Horaires adaptés à leurs études',
        ],
    },
    {
        title: 'Employeurs',
        text: 'Des talents étudians à portée de main',
        list: [
            'Accès rapide à des talents étudiants flexibles',
            "Pas de contraintes d'embauche formelle",
            'Missions sans engagement, adaptées à vos besoins',
            'Coût réduit par rapport à des services professionnels',
            'Gain de temps pour des tâches ponctuelles',
        ],
    },
];

const HomePerks = ({ goToNewsLetter }: Props) => {
    const theme = useTheme();

    return (
        <HomeSection backgroundColor={theme.colors.background.secondary}>
            <Container id={HomeSectionsDictionary.PERKS}>
                <Content>
                    <ContentTitle>Les avantages avec Wiinco ?</ContentTitle>
                    <Cards>
                        {cards.map((card, index) => (
                            <Card key={index} isSecondary={index === 1}>
                                <CardContent>
                                    <CardContentTitle>{card.title}</CardContentTitle>
                                    <CardContentText>{card.text}</CardContentText>
                                    <CardContentList>
                                        {card.list.map((item, itemIndex) => (
                                            <CardContentListItem key={`${index}-${itemIndex}`}>
                                                {item}
                                            </CardContentListItem>
                                        ))}
                                    </CardContentList>
                                </CardContent>

                                <CardCta>
                                    {index === 0 ? (
                                        <PrimaryButton isExpanded onClick={goToNewsLetter}>
                                            S'inscrire à la newsletter
                                        </PrimaryButton>
                                    ) : (
                                        <SecondaryButton isExpanded onClick={goToNewsLetter}>
                                            S'inscrire à la newsletter
                                        </SecondaryButton>
                                    )}
                                </CardCta>
                            </Card>
                        ))}
                    </Cards>
                </Content>
                <ImageContainer>
                    <Image src="https://media-public.canva.com/LnRi4/MAEP3_LnRi4/1/s2.jpg" />
                </ImageContainer>
            </Container>
        </HomeSection>
    );
};

export { HomePerks };
