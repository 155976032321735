import styled from 'styled-components';

export const Container = styled.div<{ backgroundColor?: string }>`
    width: 100vw;
    display: grid;
    grid-template-columns: 7rem minmax(2rem, 1fr) minmax(50vw, 110rem) minmax(2rem, 1fr) 7rem;
    grid-auto-rows: max-content;
    position: relative;

    ${({ backgroundColor }) => {
        if (backgroundColor != null) {
            return {
                backgroundColor: backgroundColor,
            };
        }
    }}

    ${({ theme }) => theme.breakpoints.midsize} {
        grid-template-columns: 2rem minmax(1rem, 2rem) 1fr minmax(1rem, 2rem) 2rem;
    }
`;

export const Content = styled.div<{ isVisible: boolean; animationDelay?: number }>`
    grid-column: 3 / 4;
    position: relative;
    transform: translateY(100px);
    opacity: 0;
    transition: all 0.3s ease-in;
    transition-delay: ${({ animationDelay }) => `${animationDelay ?? 0}s`};

    ${({ isVisible }) => {
        if (isVisible === true) {
            return {
                transform: 'translateY(0px)',
                opacity: 1,
            };
        }
    }}
`;
