import styled from 'styled-components';
import { ButtonAppearance } from './Button';

export const Container = styled.button<{ isExpanded: boolean; appearance: ButtonAppearance }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    width: max-content;
    padding: 0 ${({ theme }) => theme.box.spacing.md};
    cursor: pointer;
    user-select: none;
    font-size: ${({ theme }) => theme.font.size.md};
    border-radius: 1.6rem;
    box-shadow: ${({ theme }) => theme.box.shadow.main};
    transition: all 0.3s ease-in;
    &:hover {
        box-shadow: ${({ theme }) => theme.box.shadow.secondary};
    }

    ${({ isExpanded }) => {
        if (isExpanded === true) {
            return {
                width: '100%',
            };
        }
    }}

    ${({ appearance, theme }) => {
        switch (appearance) {
            case ButtonAppearance.PRIMARY: {
                return {
                    background: theme.colors.primary.gradient,
                    color: theme.colors.text.light,
                    boxShadow: 'rgb(16 40 119 / 32%) 0px 3px 18px 0px',
                };
            }
            case ButtonAppearance.SECONDARY: {
                return {
                    background: theme.colors.secondary.gradient,
                    color: theme.colors.text.light,
                    boxShadow: 'rgba(241, 89, 42, 0.34) 0px 3px 18px 0px',
                };
            }
        }
    }}
`;
