import BaseRequest from './_baseRequest';
import { BaseRequestConfig } from '../APIService.types';

class AuthenticationRequest extends BaseRequest {
    constructor(constructor: BaseRequestConfig) {
        super(constructor);
    }

    /**
     * Login
     * @param {string} email
     * @param {string} password
     * @returns {Promise<Object>}
     */
    async login(
        email: string,
        password: string,
    ): Promise<{ tokens: { accessToken: string; refreshToken: string } }> {
        return this.post('/login', false, {
            email: email,
            password: password,
        });
    }

    /**
     * Logout
     * @return {Promise<void>}
     */
    async logout(): Promise<void> {
        return this.post('/logout', true, {});
    }
}

export { AuthenticationRequest };
