import React from 'react';
import { Container, Content, Logo, LogoContainer } from './HeaderApp-styles';
import logo from '../../../assets/logo-light.png';
import { useAppContext } from '../../../hooks';
import { SecondaryButton } from '../../../components';

const HeaderApp = () => {
    const { logout } = useAppContext();
    return (
        <Container>
            <Content>
                <LogoContainer to="/">
                    <Logo src={logo} alt="Wiinco" />
                </LogoContainer>
                <SecondaryButton onClick={() => logout()}>Logout</SecondaryButton>
            </Content>
        </Container>
    );
};

export { HeaderApp };
