import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { ScrollPosition } from '../../../../hooks/useScroll';

const slideDown = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

export const Container = styled.div<{ scroll: ScrollPosition }>`
    width: 100vw;
    display: grid;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    grid-template-columns: 2rem minmax(2rem, 7rem) 1fr minmax(2rem, 7rem) 2rem;
    grid-template-rows: ${({ theme }) => theme.config.header.height.default}px;
    background-color: ${({ theme }) => theme.colors.background.main};
    box-shadow: ${({ theme }) => theme.box.shadow.main};
    transition: all 0.2s ease-in;
    animation: ${slideDown} 0.5s ease-out;

    ${({ theme }) => theme.breakpoints.midsize} {
        grid-template-columns: 2rem minmax(1rem, 2rem) 1fr minmax(1rem, 2rem) 2rem;
    }

    ${({ scroll, theme }) => {
        if (scroll.scrollY > theme.config.header.height.default) {
            return {
                transform:
                    scroll.scrollDirection === 'up' ? 'translateY(0%)' : 'translateY(-100%) !important',
            };
        }
    }}
`;

export const Content = styled.div`
    grid-column: 3 / 4;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const LogoContainer = styled(Link)`
    margin-right: ${({ theme }) => theme.box.spacing.xl};
`;

export const Logo = styled.img`
    height: 5rem;
    object-fit: contain;
`;

export const NavList = styled.div`
    display: flex;
    align-items: center;
`;

export const NavListItem = styled(Link)`
    font-size: ${({ theme }) => theme.font.size.lg};
    color: ${({ theme }) => theme.colors.text.main};
    margin: 0 ${({ theme }) => theme.box.spacing.md};
    font-weight: ${({ theme }) => theme.font.weight.bold};

    &:hover {
        color: ${({ theme }) => theme.colors.text.secondary};
    }
`;

export const NavListItemButton = styled.div`
    width: 15rem;
    margin-left: ${({ theme }) => theme.box.spacing.md};
`;
