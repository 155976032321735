import styled from 'styled-components';

export const Container = styled.div`
    height: max-content;
    overflow: hidden;
    position: relative;
`;

export const Phone = styled.img`
    width: 100%;
    object-fit: contain;
    position: relative;
    z-index: 2;
`;

export const Content = styled.img`
    width: 95%;
    height: 94%;
    object-fit: cover;
    position: absolute;
    top: 3%;
    bottom: 4%;
    left: 2%;
    right: 2%;
    margin: auto;
    z-index: 1;
    border-radius: 30px;
`;
