import styled from 'styled-components';

export const TogglePassword = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const PasswordRequirementsList = styled.ul``;

export const PasswordRequirementsListItem = styled.li<{ isValid: boolean }>`
    font-size: ${({ theme }) => theme.font.size.sm};
    padding: ${({ theme }) => `${theme.box.spacing.xxs} ${theme.box.spacing.xs}`};
    display: flex;
    align-items: center;

    svg {
        margin-right: ${({ theme }) => theme.box.spacing.xxs};
    }
`;
