import React, { useMemo, useRef } from 'react';
import { Container, Content } from './Table-styles';
import { TableProps } from './Table-types';
import TableBody from './TableBody';
import TableEmpty from './TableEmpty';
import TableHeader from './TableHeader';
import TableLoading from './TableLoading';
import { useWindowSize } from 'usehooks-ts';

const defaultStyles = {
    header: {
        height: 45,
    },
    row: {
        height: 55,
    },
    table: {
        minWidth: '100%',
    },
};

const Table = <T,>(props: TableProps<T>) => {
    const tableRef = useRef<HTMLDivElement>(null);
    const tableContainerRef = useRef<HTMLDivElement>(null);
    const windowSize = useWindowSize();

    const currentStyles = useRef({
        header: {
            height: props.styles?.header?.height ?? defaultStyles.header.height,
        },
        row: {
            height: props.styles?.row?.height ?? defaultStyles.row.height,
        },
        table: {
            minWidth: props.styles?.table?.minWidth ?? defaultStyles.table.minWidth,
        },
    });

    const renderBody = () => {
        if (props.isLoading === true) {
            return (
                <TableLoading
                    tableStyles={currentStyles.current}
                    width={tableContainerRef.current?.clientWidth}
                />
            );
        }

        if (props.data.length === 0) {
            return (
                <TableEmpty
                    tableStyles={currentStyles.current}
                    width={tableContainerRef.current?.clientWidth}
                />
            );
        }

        return <TableBody {...props} tableRef={tableRef} tableStyles={currentStyles.current} />;
    };

    const currentColumns = useMemo(() => {
        return props.columns.map((column) => {
            const breakPointConfig = column.breakPoints?.find((item) => windowSize.width <= item.breakpoint);
            if (breakPointConfig != null) {
                return {
                    ...column,
                    ...breakPointConfig,
                };
            } else {
                return { ...column };
            }
        });
    }, [props.columns, windowSize.width]);

    return (
        <Container
            columns={currentColumns}
            tableStyles={currentStyles.current}
            isLoading={props.isLoading}
            isEmpty={props.data.length === 0}
            className="table-container"
            ref={tableContainerRef}
        >
            <Content
                columns={currentColumns}
                tableStyles={currentStyles.current}
                ref={tableRef}
                isLoading={props.isLoading}
                isEmpty={props.data.length === 0}
                className="table-content"
            >
                <TableHeader {...props} />
                {renderBody()}
            </Content>
        </Container>
    );
};

export { Table };
