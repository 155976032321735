import React from 'react';
import { Container, Phone, Content } from './HomePhone-styles';
import phone from './assets/iphone.png';

type Props = {
    content: string;
};

const HomePhone = ({ content }: Props) => {
    return (
        <Container>
            <Phone src={phone} />
            <Content src={content} />
        </Container>
    );
};

export { HomePhone };
