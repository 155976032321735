import styled from 'styled-components';

export const Background = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://storage.googleapis.com/wiinco-public/hero-bg.jpg');
    background-size: cover;
    background-position: center;
    z-index: 0;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgb(1 17 38 / 83%);
    }
`;

export const Container = styled.div`
    min-height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    padding-top: ${({ theme }) => `${theme.config.header.height.default}px`};
`;

export const Content = styled.div`
    display: flex;
    height: 100%;
    width: 50%;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    z-index: 3;

    @media (max-width: 820px) {
        width: 100%;
    }
`;

export const ContentTitle = styled.div`
    margin-bottom: ${({ theme }) => theme.box.spacing.xxl};
`;

export const ContentTitleText = styled.h1`
    color: ${({ theme }) => theme.colors.text.light};
    font-size: 6rem;
    line-height: 1;
    font-weight: ${({ theme }) => theme.font.weight.normal};

    @media (max-width: 1150px) {
        font-size: 5rem;
    }
`;

export const ContentDescription = styled.div`
    color: ${({ theme }) => theme.colors.text.secondary};
    font-size: ${({ theme }) => theme.font.size.lg};
    line-height: 1.4;
    margin-bottom: ${({ theme }) => theme.box.spacing.xxl};
`;

export const ContentDescriptionText = styled.div``;

export const Cta = styled.div`
    display: flex;
    align-items: center;
`;

export const CtaButton = styled.div`
    width: 80%;
    max-width: 30rem;
    text-transform: uppercase;
    font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export const HomeHeroPhones = styled.div`
    width: 50%;
    display: flex;
    gap: ${({ theme }) => theme.box.spacing.lg};

    ${({ theme }) => theme.breakpoints.tablet} {
        gap: 0px;
        justify-content: flex-end;

        > div:last-child {
            display: none;
        }
    }

    @media (max-width: 600px) {
        display: none;
    }
`;
