import styled from 'styled-components';

export const Container = styled.div`
    padding: 7vh 0px;
    display: flex;

    @media (max-width: 1150px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const PhoneContainer = styled.div`
    display: flex;
    width: 40%;
    @media (max-width: 1150px) {
        order: 2;
        width: 100%;
        justify-content: center;
    }
`;

export const Phone = styled.div`
    width: 100%;
    max-width: 30rem;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;

    @media (max-width: 1150px) {
        order: 1;
        margin-bottom: ${({ theme }) => theme.box.spacing.lg};
        width: 100%;
    }
`;

export const ContentTitle = styled.div`
    margin-bottom: ${({ theme }) => theme.box.spacing.lg};
`;

export const ContentTitleText = styled.h1`
    font-size: 5rem;
    line-height: 1;

    @media (max-width: 1150px) {
        font-size: 4rem;
    }
`;

export const ContentDescription = styled.div`
    width: 100%;
    margin-right: ${({ theme }) => theme.box.spacing.lg};
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.box.spacing.lg};
`;

export const ContentDescriptionText = styled.div`
    font-size: ${({ theme }) => theme.font.size.lg};
    line-height: 1.4;
`;

export const List = styled.ul``;

export const ListItem = styled.li`
    display: grid;
    align-items: center;
    grid-template-columns: max-content 1fr;
    grid-auto-rows: max-content;
    column-gap: ${({ theme }) => theme.box.spacing.lg};

    @media (max-width: 1150px) {
        max-width: 80rem;
    }
`;

export const ListItemIcon = styled.div`
    svg {
        font-size: 4rem;

        ${({ theme }) => theme.breakpoints.mobile} {
            font-size: 3rem;
        }
    }
`;

export const ListIemTitle = styled.h4`
    font-size: ${({ theme }) => theme.font.size.xl};
    line-height: 1.2;
`;

export const ListItemText = styled.div`
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    font-size: ${({ theme }) => theme.font.size.lg};
    line-height: 1.4;
    border-bottom: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    margin-bottom: ${({ theme }) => theme.box.spacing.lg};
    padding-bottom: ${({ theme }) => theme.box.spacing.lg};
`;
