import { Locale } from '../types';

export const config = {
    locales: {
        supported: [...Object.values(Locale)],
        default: Locale.FR,
    },
    socialNetworks: {
        linkedin: {
            url: 'https://www.linkedin.com/company/wiinco/',
        },
        instagram: {
            url: 'https://www.instagram.com/wiinco_app/',
        },
    },
};
