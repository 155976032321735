import React, { useState } from 'react';
import {
    Container,
    Icons,
    Navigation,
    NavigationItem,
    Section,
    SectionTitle,
    Content,
    NewsLetter,
    Footer,
    Icon,
} from './HomeFooter-styles';
import { FaLinkedin, FaInstagram } from 'react-icons/fa6';
import { HomeSectionsDictionary } from '../../Home-types';
import { SecondaryButton, TextInput } from '../../../../components';
import moment from 'moment';
import { usePromises } from '../../../../hooks';
import { emailRegex } from '../../../../tools';
import { APIService } from '../../../../services';
import { config } from '../../../../config/main';

const HomeFooter = () => {
    const [email, setEmail] = useState('');
    const [{ executePromise }] = usePromises();

    const subscribe = async () => {
        await executePromise(async () => {
            if (emailRegex.test(email) !== true) {
                return;
            }

            await APIService.subscribers.postSubscriber({
                firstName: '',
                lastName: '',
                email: email,
            });
        });
    };

    return (
        <Container>
            <Content>
                <Section className="navigate">
                    <SectionTitle>Naviguer</SectionTitle>
                    <Navigation>
                        <NavigationItem to={`#${HomeSectionsDictionary.INTRO}`}>À propos</NavigationItem>
                        <NavigationItem to={`#${HomeSectionsDictionary.PERKS}`}>Avantages</NavigationItem>
                        <NavigationItem to={`#${HomeSectionsDictionary.NEWS_LETTER}`}>
                            Newsletter
                        </NavigationItem>
                    </Navigation>
                </Section>
                <Section className="social">
                    <SectionTitle>Réseaux sociaux</SectionTitle>
                    <Icons>
                        <Icon target="_blank" href={config.socialNetworks.linkedin.url}>
                            <FaLinkedin />
                        </Icon>
                        <Icon target="_blank" href={config.socialNetworks.instagram.url}>
                            <FaInstagram />
                        </Icon>
                    </Icons>
                </Section>
                <Section className="newsletter">
                    <SectionTitle>Newsletter</SectionTitle>
                    <NewsLetter>
                        <TextInput placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                        <SecondaryButton onClick={subscribe}>S'inscrire</SecondaryButton>
                    </NewsLetter>
                </Section>
                <Footer>&copy; {moment().year()}, Wiinco - Tous droits réservés</Footer>
            </Content>
        </Container>
    );
};

export { HomeFooter };
