import React from 'react';
import { Container, Footer, FooterIcon, Menu, MenuItem } from './HeaderPublicMobileMenu-styles';
import { FaLinkedin, FaInstagram } from 'react-icons/fa6';
import { HomeSectionsDictionary } from '../../../../../../pages';
import { config } from '../../../../../../config/main';

type Props = {
    isOpened: boolean;
    toggleMenu: (state?: boolean) => void;
};

const HeaderPublicMobileMenu = ({ isOpened, toggleMenu }: Props) => {
    return (
        <Container isOpened={isOpened}>
            <Menu>
                <MenuItem to={`#${HomeSectionsDictionary.INTRO}`} onClick={() => toggleMenu()}>
                    À propos
                </MenuItem>
                <MenuItem to={`#${HomeSectionsDictionary.PERKS}`} onClick={() => toggleMenu()}>
                    Avantages
                </MenuItem>
                <MenuItem to={`#${HomeSectionsDictionary.NEWS_LETTER}`} onClick={() => toggleMenu()}>
                    Newsletter
                </MenuItem>
            </Menu>
            <Footer>
                <FooterIcon target="_blank" href={config.socialNetworks.linkedin.url}>
                    <FaLinkedin />
                </FooterIcon>
                <FooterIcon target="_blank" href={config.socialNetworks.instagram.url}>
                    <FaInstagram />
                </FooterIcon>
            </Footer>
        </Container>
    );
};

export { HeaderPublicMobileMenu };
