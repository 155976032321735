import BaseRequest from './_baseRequest';
import { BaseRequestConfig } from '../APIService.types';
import { User } from '../../../types';

class UsersRequest extends BaseRequest {
    constructor(config: BaseRequestConfig) {
        super(config);
    }

    async getProfile(): Promise<User> {
        return this.get('/me', true);
    }
}

export { UsersRequest };
