import React from 'react';
import { Container, Title } from './DashboardSubscribers-styles';
import { usePromises } from '../../../hooks';
import { APIService } from '../../../services';
import { Subscriber } from '../../../types';
import { useTranslation } from 'react-i18next';
import { Table, TableColumnConfig } from '../../../components';
import { useTable } from '../../../hooks/useTable';
import { FaUsers } from 'react-icons/fa';

const DashboardSubscribersPage = () => {
    const { t } = useTranslation();
    const [, [subscribers]] = usePromises(() => APIService.subscribers.listSubscribers());

    const columns: Array<TableColumnConfig<Subscriber>> = [
        {
            id: 'email',
            headerTitle: t('generic.email'),
            getValue: ({ email }) => email,
            width: '1fr',
        },
        {
            id: 'firstName',
            headerTitle: t('generic.first_name'),
            getValue: ({ firstName }) => firstName,
            width: '1fr',
        },
        {
            id: 'lastName',
            headerTitle: t('generic.last_name'),
            getValue: ({ lastName }) => lastName,
            width: '1fr',
        },
    ];

    const { data, displayedColumns, isLoading } = useTable(subscribers.data, columns);

    return (
        <Container>
            <Title>
                <FaUsers /> Subscribers
            </Title>
            <Table data={data} columns={displayedColumns} isLoading={isLoading} />
        </Container>
    );
};

export { DashboardSubscribersPage };
