import React, { useState } from 'react';
import { HomeSection } from '../../components';
import {
    CheckBoxChoice,
    CheckBoxContainer,
    CheckBoxText,
    Container,
    FormContainer,
    SuccessMessage,
    Text,
    Title,
} from './HomeNewsLetter-styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { emailRegex } from '../../../../tools';
import {
    CheckboxInput,
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormTextInput,
} from '../../../../components';
import { useTranslation } from 'react-i18next';
import { usePromises } from '../../../../hooks';
import { APIService } from '../../../../services';
import { HomeSectionsDictionary } from '../../Home-types';

const HomeNewsLetter = () => {
    const { t } = useTranslation();
    const [userType, setUserType] = useState<'student' | 'employee'>('student');
    const [showSuccess, setShowSuccess] = useState(false);
    const [{ executePromise }] = usePromises();

    const validationSchema = Yup.object({
        firstName: Yup.string().notRequired(),
        lastName: Yup.string().notRequired(),
        email: Yup.string()
            .required(t('generic.errors.required_field'))
            .matches(emailRegex, t('generic.errors.invalid_email')),
    });

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            await executePromise(async () => {
                await APIService.subscribers.postSubscriber({
                    firstName: submittedValues.firstName,
                    lastName: submittedValues.lastName,
                    email: submittedValues.email,
                });
                setShowSuccess(true);
                setTimeout(() => {
                    setShowSuccess(false);
                }, 1000);
            });
        },
    });

    const onClickCheckbox = (type: 'student' | 'employee', isChecked: boolean) => {
        if (isChecked === true && userType !== type) {
            setUserType(type);
        }
    };

    return (
        <HomeSection>
            <Container id={HomeSectionsDictionary.NEWS_LETTER}>
                <Title>Envie d’être les premiers à découvrir Wiinco ?</Title>
                <Text>
                    Inscrivez-vous à notre newsletter et soyez informés en exclusivité du lancement de
                    l’application !
                </Text>
                {showSuccess === false ? (
                    <FormContainer>
                        <Form formik={formik} validationSchema={validationSchema}>
                            <FormGroup name="firstName">
                                <FormGroupLabel>{t('generic.first_name')}</FormGroupLabel>
                                <FormTextInput type="text" placeholder={t('generic.first_name')} />
                            </FormGroup>
                            <FormGroup name="lastName">
                                <FormGroupLabel>{t('generic.last_name')}</FormGroupLabel>
                                <FormTextInput type="text" placeholder={t('generic.last_name')} />
                            </FormGroup>
                            <FormGroup name="email" isExpanded>
                                <FormGroupLabel>{t('generic.email')}</FormGroupLabel>
                                <FormTextInput
                                    type="email"
                                    placeholder={t('generic.email')}
                                    autoComplete="new-password"
                                />
                            </FormGroup>
                            <CheckBoxContainer>
                                <CheckBoxText>Êtes-vous:</CheckBoxText>
                                <CheckBoxChoice>
                                    <CheckboxInput
                                        label="Un(e) étudiant(e)"
                                        checked={userType === 'student'}
                                        onChange={(value) => onClickCheckbox('student', value)}
                                    />
                                </CheckBoxChoice>
                                <CheckBoxChoice>
                                    <CheckboxInput
                                        label="Un(e) professionel(le)"
                                        checked={userType === 'employee'}
                                        onChange={(value) => onClickCheckbox('employee', value)}
                                    />
                                </CheckBoxChoice>
                            </CheckBoxContainer>
                            <FormFooter submitLabel={t('generic.subscribe_news_letter')} isExpanded />
                        </Form>
                    </FormContainer>
                ) : (
                    <SuccessMessage>Merci d'avoir rejoint Wiinco</SuccessMessage>
                )}
            </Container>
        </HomeSection>
    );
};

export { HomeNewsLetter };
