import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppContext } from '../../hooks';

type Props = {
    children?: React.ReactNode;
};

const AuthenticatedRoute = ({ children }: Props) => {
    const [isMounted, setIsMounted] = useState(false);
    const { isAuthenticated } = useAppContext();

    useEffect(() => {
        setIsMounted(true);
    }, []);

    if (isAuthenticated === false) {
        // The user is not authenticated and cannot access the route, redirect to login
        return <Navigate to="/login" />;
    }

    if (isMounted === false) {
        // Wait for useEffect to run once before displaying children
        return <React.Fragment />;
    }

    if (typeof children === 'object') {
        // The current route does not have sub paths
        return <React.Fragment>{children}</React.Fragment>;
    } else {
        // The current route has sub path
        return <Outlet />;
    }
};

export { AuthenticatedRoute };
