export const colors = {
    primary: {
        main: '#1b2b44',
        gradient: 'linear-gradient(54deg, #1b2b44, #235199)',
    },
    secondary: {
        main: 'rgb(255, 154, 80)',
        gradient: 'linear-gradient(90deg, rgb(247, 69, 69) 0%, rgb(255, 154, 80) 100%)',
    },
    text: {
        main: '#1b2b44',
        secondary: '#aba2a2',
        tertiary: '#d7cece',
        disabled: 'grey',
        light: '#ffffff',
    },
    danger: {
        main: 'red',
    },
    background: {
        main: '#ffffff',
        secondary: '#ededed',
    },
};
