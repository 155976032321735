export const config = {
    header: {
        height: {
            default: 70,
        },
    },
    input: {
        height: {
            default: 40,
        },
    },
};
