import React from 'react';
import {
    Container,
    Content,
    Logo,
    LogoContainer,
    NavList,
    NavListItem,
    NavListItemButton,
} from './HeaderPublicDesktop-styles';
import logo from '../../../../assets/logo-dark.png';
import { HomeSectionsDictionary } from '../../../../pages';
import { PrimaryButton } from '../../../../components';
import { useScroll } from '../../../../hooks';

const HeaderPublicDesktop = () => {
    const scroll = useScroll();

    return (
        <Container scroll={scroll}>
            <Content>
                <LogoContainer to="/">
                    <Logo src={logo} alt="Wiinco" />
                </LogoContainer>
                <NavList>
                    <NavListItem to={`#${HomeSectionsDictionary.INTRO}`}>À propos</NavListItem>
                    <NavListItem to={`#${HomeSectionsDictionary.PERKS}`}>Avantages</NavListItem>
                    <NavListItemButton>
                        <PrimaryButton isExpanded path={`#${HomeSectionsDictionary.NEWS_LETTER}`}>
                            Newsletter
                        </PrimaryButton>
                    </NavListItemButton>
                </NavList>
            </Content>
        </Container>
    );
};

export { HeaderPublicDesktop };
