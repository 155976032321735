import React, { forwardRef, useContext, useMemo } from 'react';
import { TextInput, TextInputProps } from '../../Inputs';
import { FormContext } from '../Form/Form';
import { FormGroupContext } from '../FormGroup';

const FormTextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
    const { formik } = useContext(FormContext);
    const { name } = useContext(FormGroupContext);

    const value = useMemo(() => {
        let result = formik.values;
        const keys = name.split('.');

        for (const key of keys) {
            if (result[key] != null) {
                result = result[key];
            }
        }
        return typeof result === 'string' || typeof result === 'number' ? result : '';
    }, [formik.values, name]);

    return (
        <TextInput
            {...props}
            ref={ref}
            name={name}
            onChange={formik.handleChange}
            value={value}
            onBlur={formik.handleBlur}
        />
    );
});

export { FormTextInput };
