export enum ErrorsCode {
    // GENERAL ERROR
    INVALID_PARAMETER = 1001,
    INVALID_HEADERS = 1002,

    // CREDENTIALS ERROR
    INVALID_CREDENTIALS = 1003,
    INVALID_ACCESS_TOKEN = 1004,
    INVALID_REFRESH_TOKEN = 1005,
}
