import styled from 'styled-components';

export const Container = styled.div`
    padding: 7vh 0;
    display: flex;
    margin-bottom: ${({ theme }) => theme.box.spacing.xxl};

    ${({ theme }) => theme.breakpoints.mobile} {
        padding: 5vh 0;
        margin-bottom: ${({ theme }) => theme.box.spacing.lg};
    }
`;

export const Content = styled.div`
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1200px) {
        width: 100%;
    }
`;

export const ContentTitle = styled.h1`
    margin-bottom: ${({ theme }) => theme.box.spacing.xxl};
    font-size: 5rem;
    margin-top: ${({ theme }) => theme.box.spacing.xl};
    line-height: 1.4;

    @media (max-width: 1150px) {
        font-size: 4rem;
    }

    ${({ theme }) => theme.breakpoints.midsize} {
        text-align: center;
    }
`;

export const Cards = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.box.spacing.xl};
    padding-right: ${({ theme }) => theme.box.spacing.xl};

    ${({ theme }) => theme.breakpoints.midsize} {
        flex-direction: column;
    }

    @media (max-width: 1200px) {
        padding-right: 0px;
    }
`;

export const Card = styled.div<{ isSecondary: boolean }>`
    background: ${({ theme }) => theme.colors.background.main};
    padding: ${({ theme }) => theme.box.spacing.lg};
    border-radius: 1.6rem;
    color: ${({ theme }) => theme.colors.text.main};
    flex: 1;
    display: flex;
    flex-direction: column;
    box-shadow: ${({ theme }) => theme.box.shadow.main};

    ${({ isSecondary, theme }) => {
        if (isSecondary === true) {
            return {
                h2: {
                    color: theme.colors.secondary.main,
                },
            };
        }
    }}
`;

export const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const CardContentTitle = styled.h2`
    font-size: ${({ theme }) => theme.font.size.xxl};
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
`;

export const CardContentText = styled.div`
    margin-bottom: ${({ theme }) => theme.box.spacing.lg};
    font-size: ${({ theme }) => theme.font.size.lg};
    height: 5rem;
    line-height: 1.4;

    ${({ theme }) => theme.breakpoints.midsize} {
        height: unset;
    }
`;

export const CardContentList = styled.ul`
    margin-bottom: ${({ theme }) => theme.box.spacing.xxl};
    list-style: circle;
    padding-left: ${({ theme }) => theme.box.spacing.lg};
`;

export const CardContentListItem = styled.li`
    font-size: ${({ theme }) => theme.font.size.md};
    margin-bottom: ${({ theme }) => theme.box.spacing.sm};
    line-height: 1.4;
`;

export const CardCta = styled.div`
    display: flex;
    justify-content: center;
`;

export const ImageContainer = styled.div`
    position: relative;
    width: 30%;
    height: 55vh;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgb(1 17 38 / 83%);
    }

    @media (max-width: 1200px) {
        display: none;
    }
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
