import React from 'react';
import { useAppContext } from '../../hooks';
import { useLocation } from 'react-router-dom';
import { Routes } from '../../routes';
import { HeaderApp } from './HeaderApp';
import { HeaderPublic } from './HeaderPublic';

const Header = () => {
    const location = useLocation();
    const { isAuthenticated } = useAppContext();
    if (isAuthenticated === true && location.pathname.includes(Routes.DASHBOARD)) {
        return <HeaderApp />;
    }
    return <HeaderPublic />;
};

export { Header };
