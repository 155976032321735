import React, { useContext } from 'react';
import { Container } from './FormFooter-styles';
import { PrimaryButton, SecondaryButton } from '../../Button';
import { useTranslation } from 'react-i18next';
import { FormContext } from '../Form/Form';

type Props = {
    submitLabel: string;
    isExpanded?: boolean;
    secondary?: {
        label?: string;
        onClick: () => void;
    };
    children?: React.ReactNode;
};

const FormFooter = ({ submitLabel, secondary, children, isExpanded = false }: Props) => {
    const { t } = useTranslation();

    const {
        formik: { isSubmitting },
    } = useContext(FormContext);

    return (
        <Container isExpanded={isExpanded}>
            {children != null && children}
            {secondary && isSubmitting === false && (
                <SecondaryButton
                    isExpanded={isExpanded}
                    disabled={isSubmitting}
                    onClick={secondary.onClick}
                    type="button"
                >
                    {secondary.label ?? t('generic.cancel')}
                </SecondaryButton>
            )}

            <PrimaryButton
                isExpanded={isExpanded}
                type="submit"
                disabled={isSubmitting}
                isLoading={isSubmitting}
            >
                {submitLabel}
            </PrimaryButton>
        </Container>
    );
};

export { FormFooter };
