import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    width: 100vw;
    height: ${({ theme }) => `${theme.config.header.height.default}px`};
    padding: 0 ${({ theme }) => theme.box.spacing.lg};
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const LogoContainer = styled(Link)`
    margin-right: ${({ theme }) => theme.box.spacing.xl};
    position: relative;
    z-index: 9;
`;

export const Logo = styled.img`
    height: 4rem;
    object-fit: contain;
`;
