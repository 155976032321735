import React from 'react';
import { Circle, Container, HiddenCheckbox, Label } from './CheckboxInput-styles';

type Props = {
    checked: boolean;
    onChange: (isChecked: boolean) => void;
    label?: string;
};

const CheckboxInput = ({ checked, onChange, label }: Props) => {
    return (
        <Container>
            <HiddenCheckbox checked={checked} onChange={(e) => onChange(e.target.checked)} />
            <Circle />
            {label && <Label>{label}</Label>}
        </Container>
    );
};

export { CheckboxInput };
