import styled from 'styled-components';
import { BannerAppearance } from './Banner';

export const Container = styled.div<{
    appearance: BannerAppearance;
    hasTitle: boolean;
    isClickable: boolean;
}>`
    width: 100%;
    padding: ${({ theme }) => theme.box.spacing.sm};
    margin: ${({ theme }) => theme.box.spacing.md} 0;
    border-radius: 4px;
    position: relative;
    display: flex;
    align-items: center;

    ${({ appearance, theme }) => {
        if (appearance === BannerAppearance.ERROR) {
            return {
                border: `1px solid ${theme.colors.danger.main}`,
                color: theme.colors.danger.main,
            };
        }

        if (appearance === BannerAppearance.WARNING) {
            return {
                // border: `1px solid ${theme.colors.yellow.main}`,
                // color: theme.colors.yellow.main,
                // background: theme.colors.yellow.extended_10,
            };
        }

        if (appearance === BannerAppearance.SUCCESS) {
            return {
                // border: `1px solid ${theme.colors.green.main}`,
                // color: theme.colors.green.main,
                // background: theme.colors.green.extended_10,
            };
        }
    }}

    ${({ hasTitle, theme }) => {
        if (hasTitle === true) {
            return {
                alignItems: 'unset',
                flexDirection: 'column',
                paddingBottom: theme.box.spacing.md,
                '.banner__content': {
                    marginLeft: `calc(${theme.box.spacing.xl} + ${theme.box.spacing.sm})`,
                    color: theme.colors.text.main,
                },
                '.banner__close-icon': {
                    position: 'absolute',
                    top: theme.box.spacing.sm,
                    right: theme.box.spacing.sm,
                    zIndex: 2,
                },
            };
        }
    }}

    ${({ isClickable }) => {
        if (isClickable === true) {
            return {
                cursor: 'pointer',
            };
        }
    }}
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const Title = styled.h2`
    color: 'black';
`;

export const IconContainer = styled.div`
    margin-right: ${({ theme }) => theme.box.spacing.sm};

    svg {
        font-size: ${({ theme }) => theme.font.size.xxl};
    }
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: ${({ theme }) => theme.font.size.sm};
    line-height: 1.4;
    flex: 1;
`;

export const CloseIcon = styled.div`
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
        font-size: ${({ theme }) => theme.font.size.md};
    }
    :hover {
    }
`;
