import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div<{ isOpened: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8;
    width: 100vw;
    height: 100vh;
    transform: translateY(-100%);
    transition: all 0.3s ease-in;
    background-color: ${({ theme }) => theme.colors.background.main};
    padding: ${({ theme }) => theme.box.spacing.md};
    padding-top: ${({ theme }) => `${theme.config.header.height.default}px`};
    display: flex;
    flex-direction: column;
    opacity: 0;

    ${({ isOpened }) => {
        if (isOpened === true) {
            return {
                transform: 'translateY(0%)',
                opacity: 1,
            };
        }
    }}
`;

export const Menu = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
`;

export const MenuItem = styled(Link)`
    text-align: center;
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.font.size.lg};
    margin: ${({ theme }) => theme.box.spacing.lg} 0;
    color: ${({ theme }) => theme.colors.primary.main} !important;
    font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export const Footer = styled.div`
    display: flex;
    justify-content: center;
    padding: ${({ theme }) => theme.box.spacing.xxl} 0;
`;

export const FooterIcon = styled.a`
    margin: 0 ${({ theme }) => theme.box.spacing.md};
    color: ${({ theme }) => theme.colors.primary.main} !important;

    svg {
        font-size: ${({ theme }) => theme.font.size.xl};
    }
`;
