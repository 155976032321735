import React, { useEffect, useRef } from 'react';
import { Container, Content } from './Login-styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useAppContext, usePromises } from '../../hooks';
import {
    Banner,
    BannerAppearance,
    Form,
    FormFooter,
    FormGroup,
    FormGroupLabel,
    FormPasswordInput,
    FormTextInput,
} from '../../components';

const LoginPage = () => {
    const { t } = useTranslation();
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [{ error, clearError, executePromise }] = usePromises();
    const { login } = useAppContext();

    useEffect(() => {
        emailInputRef.current?.focus();
    }, []);

    const validationSchema = Yup.object({
        email: Yup.string()
            .email(t('generic.errors.invalid_email') as string)
            .required(t('generic.errors.required_field') as string),
        password: Yup.string().required(t('generic.errors.required_field') as string),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (submittedValues) => {
            await executePromise(async () => {
                await login(submittedValues);
            });
        },
    });

    return (
        <Container>
            <Content>
                {error && (
                    <Banner appearance={BannerAppearance.ERROR} closeBanner={clearError}>
                        {t('generic.errors.invalid_credentials')}
                    </Banner>
                )}
                <Form formik={formik} validationSchema={validationSchema} oneColumn>
                    <FormGroup name="email">
                        <FormGroupLabel>{t('generic.email')}</FormGroupLabel>
                        <FormTextInput type="email" placeholder={t('generic.email')} ref={emailInputRef} />
                    </FormGroup>
                    <FormGroup name="password">
                        <FormGroupLabel>{t('generic.password')}</FormGroupLabel>
                        <FormPasswordInput placeholder={t('generic.password')} />
                    </FormGroup>
                    <FormFooter submitLabel={t('generic.sign_in')} isExpanded />
                </Form>
            </Content>
        </Container>
    );
};

export { LoginPage };
