import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppContext } from '../../hooks';
import { Routes } from '../dictionary';

type Props = {
    children?: React.ReactNode;
};

const UnauthenticatedRoute = ({ children }: Props) => {
    const { isAuthenticated } = useAppContext();

    if (isAuthenticated === true) {
        // The user is authenticated and cannot access the route, redirect to "/"
        return <Navigate to={`/${Routes.DASHBOARD}`} />;
    }

    if (typeof children === 'object') {
        // The current route does not have sub paths
        return <React.Fragment>{children}</React.Fragment>;
    } else {
        // The current route has sub path
        return <Outlet />;
    }
};

export { UnauthenticatedRoute };
