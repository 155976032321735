import styled from 'styled-components';

export const Container = styled.div<{
    isExpanded: boolean;
}>`
    grid-column: 1 / -1;
    display: flex;
    align-items: center;
    margin-top: ${({ theme }) => theme.box.spacing.md};

    ${({ isExpanded, theme }) => {
        if (isExpanded === false) {
            return {
                justifyContent: 'flex-end',
                button: {
                    marginLeft: theme.box.spacing.sm,
                },
            };
        }
    }}
`;
