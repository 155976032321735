import styled, { css } from 'styled-components';

const inputStyle = css`
    width: 100%;
    height: ${({ theme }) => theme.config.input.height.default}px;
    font-size: ${({ theme }) => theme.font.size.md};
    padding: 0 ${({ theme }) => theme.box.spacing.md};
    border-radius: 0.8rem;
    background: ${({ theme }) => theme.colors.background.main};

    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
        outline: none;
        border-color: ${({ theme }) => theme.colors.text.main};
    }

    &::placeholder {
        color: ${({ theme }) => theme.colors.text.tertiary};
    }

    &:disabled {
        color: ${({ theme }) => theme.colors.text.disabled};
    }
`;

export const Container = styled.div<{
    textarea: boolean;
}>`
    width: 100%;
    height: ${({ theme }) => theme.config.input.height.default}px;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    z-index: 1;
    background: ${({ theme }) => theme.colors.background.main};
    font-size: ${({ theme }) => theme.font.size.md};
    border-radius: 0.8rem;
    overflow: hidden;

    ${({ textarea }) => {
        if (textarea === true) {
            return {
                height: 'unset',
                minHeight: 'max-content',
            };
        }
    }}
`;

export const Input = styled.input`
    ${inputStyle}
`;

export const TextArea = styled.textarea`
    ${inputStyle};
    min-height: 15rem;
    max-height: 30rem;
    font-family: 'Roboto', sans-serif !important;
    padding: ${({ theme }) => theme.box.spacing.md};
    resize: vertical;
    overflow-y: auto;
`;

export const InputInnerLabel = styled.div`
    font-size: ${({ theme }) => theme.font.size.sm};
    color: ${({ theme }) => theme.colors.text.secondary};
    padding-left: ${({ theme }) => theme.box.spacing.sm};
    min-width: max-content;
    height: max-content;
    display: flex;
    align-items: center;
    line-height: 1.4;
`;

export const LeadingIconContainer = styled.div`
    width: min-content;
    min-width: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TrailingIconContainer = styled.div`
    width: min-content;
    min-width: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        font-size: 2rem;
        cursor: pointer;
        color: ${({ theme }) => theme.colors.text.secondary};
    }
`;
