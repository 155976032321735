import React from 'react';
import { useWindowSize } from 'usehooks-ts';
import { HeaderPublicDesktop } from './HeaderPublicDesktop';
import { HeaderPublicMobile } from './HeaderPublicMobile';

const HeaderPublic = () => {
    const windowSize = useWindowSize();

    return windowSize.width > 600 ? <HeaderPublicDesktop /> : <HeaderPublicMobile />;
};

export { HeaderPublic };
