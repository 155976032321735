import React from 'react';
import { HomePhone, HomeSection } from '../../components';
import {
    Container,
    Content,
    ContentTitle,
    ContentTitleText,
    ContentDescription,
    ContentDescriptionText,
    Cta,
    CtaButton,
    HomeHeroPhones,
    Background,
} from './HomeHero-styles';
import { SecondaryButton } from '../../../../components';

type Props = {
    goToNewsLetter: () => void;
};

const HomeHero = ({ goToNewsLetter }: Props) => {
    return (
        <HomeSection renderBackground={() => <Background />} animationDelay={0.5}>
            <Container>
                <Content>
                    <ContentTitle>
                        <ContentTitleText>L'application qui</ContentTitleText>
                        <ContentTitleText>simplifie vos</ContentTitleText>
                        <ContentTitleText>besoins ponctuels</ContentTitleText>
                    </ContentTitle>
                    <ContentDescription>
                        <ContentDescriptionText>Des missions faciles pour étudiants,</ContentDescriptionText>
                        <ContentDescriptionText>
                            des solutions pratiques pour entrepreneurs.
                        </ContentDescriptionText>
                    </ContentDescription>
                    <Cta>
                        <CtaButton>
                            <SecondaryButton isExpanded onClick={goToNewsLetter}>
                                S'inscrire à la newsletter
                            </SecondaryButton>
                        </CtaButton>
                    </Cta>
                </Content>
                <HomeHeroPhones>
                    <HomePhone content="https://storage.cloud.google.com/wiinco-public/hero1.png" />
                    <HomePhone content="https://storage.cloud.google.com/wiinco-public/hero2.png" />
                </HomeHeroPhones>
            </Container>
        </HomeSection>
    );
};

export { HomeHero };
