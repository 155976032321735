import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
    width: 100vw;
    display: grid;
    grid-template-columns: 2rem 0px 1fr 0px 2rem;
    grid-template-rows: ${({ theme }) => theme.config.header.height.default}px;
    background-color: ${({ theme }) => theme.colors.primary.main};
`;

export const Content = styled.div`
    grid-column: 3 / 4;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const LogoContainer = styled(Link)``;

export const Logo = styled.img`
    height: 5rem;
    object-fit: contain;
`;
