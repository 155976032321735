import styled from 'styled-components';

export const Container = styled.form<{
    oneColumn?: boolean;
}>`
    display: grid;
    grid-template-columns: ${({ oneColumn }) => (oneColumn === true ? '1fr' : 'repeat(2, 1fr)')};
    grid-auto-rows: max-content;
    row-gap: ${({ theme }) => theme.box.spacing.xl};
    column-gap: ${({ theme }) => theme.box.spacing.sm};

    ${({ theme }) => theme.breakpoints.mobile} {
        grid-template-columns: 1fr;
    }
`;
