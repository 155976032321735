import React from 'react';
import { LocaleObject } from '../../types';
import { useLocale } from '../../hooks';
import { formatLocalizedText } from '../../tools';

type Props = {
    text: LocaleObject;
};

const LocalizedText = ({ text }: Props) => {
    const locale = useLocale();
    return <>{formatLocalizedText(text, locale)}</>;
};

export { LocalizedText };
