import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h1`
    margin-bottom: ${({ theme }) => theme.box.spacing.lg};
    display: flex;
    align-items: center;

    svg {
        margin-right: ${({ theme }) => theme.box.spacing.sm};
    }
`;
