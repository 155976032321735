import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    min-height: ${({ theme }) => `calc(100vh - ${theme.config.header.height.default}px)`};
    display: flex;
    justify-content: center;
    padding-top: 20vh;
`;

export const Content = styled.div`
    width: 90%;
    max-width: 50rem;
`;
