import styled from 'styled-components';

export const Container = styled.div<{ isOpened: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 2.4rem;
    width: 3.4rem;
    position: relative;
    z-index: 9;

    & > div:nth-child(1) {
        transform-origin: top left;
        transform: rotate(${({ isOpened }) => (isOpened ? '40deg' : '0')});
    }

    & > div:nth-child(2) {
        transform-origin: center center;
        transform: scaleX(${({ isOpened }) => (isOpened ? 0 : 1)});
    }

    & > div:nth-child(3) {
        transform-origin: bottom left;
        transform: rotate(${({ isOpened }) => (isOpened ? '-40deg' : '0')});
    }
`;

export const Line = styled.div`
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.primary.main};
    transition: all 0.3s ease;
`;
