import styled from 'styled-components';

export const Container = styled.div<{
    isExpanded?: boolean;
}>`
    width: 100%;
    height: max-content;
    position: relative;

    ${({ isExpanded }) => {
        if (isExpanded === true) {
            return {
                gridColumn: '1/-1',
            };
        }
    }}
`;

export const Error = styled.div`
    color: ${({ theme }) => theme.colors.danger.main};
    font-size: ${({ theme }) => theme.font.size.sm};
    margin-top: ${({ theme }) => theme.box.spacing.md};
`;
