import { APIService } from '../services';

export const initialize = async () => {
    try {
        if (APIService.isAuthenticated() === true) {
            const user = await APIService.users.getProfile();
            return user;
        }
    } catch (err) {
        APIService.removeCredentials();
        throw err;
    }
};

export const login = async (payload: { email: string; password: string }) => {
    const { tokens } = await APIService.authentication.login(payload.email, payload.password);
    APIService.setCredentials(tokens.accessToken, tokens.refreshToken);
    const user = await APIService.users.getProfile();
    return user;
};

export const logout = () => {
    APIService.removeCredentials();
};
