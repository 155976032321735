import { Locale } from '../../types';
import { LocalStorageServiceDictionary } from './LocalStorageService-dictionary';

class LocalStorageService {
    // ******************************************************
    // CREDENTIALS
    // ******************************************************

    static getCredentials() {
        return {
            accessToken: localStorage.getItem(LocalStorageServiceDictionary.ACCESS_TOKEN),
            refreshToken: localStorage.getItem(LocalStorageServiceDictionary.REFRESH_TOKEN),
        };
    }
    static removeCredentials() {
        localStorage.removeItem(LocalStorageServiceDictionary.ACCESS_TOKEN);
        localStorage.removeItem(LocalStorageServiceDictionary.REFRESH_TOKEN);
    }

    static saveCredentials(accessToken: string, refreshToken: string) {
        localStorage.setItem(LocalStorageServiceDictionary.ACCESS_TOKEN, accessToken);
        localStorage.setItem(LocalStorageServiceDictionary.REFRESH_TOKEN, refreshToken);
    }

    // ******************************************************
    // CREDENTIALS
    // ******************************************************
    static getLocale(): Locale | null {
        return localStorage.getItem(LocalStorageServiceDictionary.LOCALE) as Locale;
    }

    static saveLocale(locale: Locale) {
        localStorage.setItem(LocalStorageServiceDictionary.LOCALE, locale);
    }
}

export { LocalStorageService };
