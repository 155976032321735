import styled from 'styled-components';

export const Container = styled.label`
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    user-select: none;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
    position: absolute;
    opacity: 0;
    cursor: pointer;
`;

export const Circle = styled.span`
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 2px solid #333;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.2s;
    position: relative;

    ${HiddenCheckbox}:checked + & {
        background-color: #fff; /* Outer circle stays white */
        border-color: ${({ theme }) => theme.colors.primary.main};
    }

    /* Inner smaller circle */
    ${HiddenCheckbox}:checked + &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 10px; /* Smaller than the wrapping circle */
        width: 10px;
        background-color: ${({ theme }) => theme.colors.primary.main};
        border-radius: 50%;
    }

    ${HiddenCheckbox}:focus + & {
        box-shadow: 0 0 3px ${({ theme }) => theme.colors.primary.main};
    }
`;

export const Label = styled.span`
    font-size: ${({ theme }) => theme.font.size.md};
    margin-left: ${({ theme }) => theme.font.size.sm};
`;
