import React from 'react';
import { Container, Navigation, NavigationItem } from './DashboardSidebar-styles';
import { Routes } from '../../../../routes';
import { FaUsers } from 'react-icons/fa';

const DashboardSidebar = () => {
    return (
        <Container>
            <Navigation>
                <NavigationItem to={`/${Routes.DASHBOARD}`} end>
                    <FaUsers />
                    Subscribers
                </NavigationItem>
            </Navigation>
        </Container>
    );
};

export { DashboardSidebar };
