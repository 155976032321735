import React from 'react';
import { Button, ButtonAppearance, ButtonProps } from '../Button';

const PrimaryButton = ({ children, ...rest }: Omit<ButtonProps, 'appearance'>) => {
    return (
        <Button {...rest} appearance={ButtonAppearance.PRIMARY}>
            {children}
        </Button>
    );
};

export { PrimaryButton };
