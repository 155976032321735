import axios, { AxiosRequestConfig } from 'axios';
import { i18n } from '../../translations';

// modules
import { AuthenticationRequest, UsersRequest, SubscriberRequests } from './modules';
// types
import {
    APIHeader,
    APIQueuedRequest,
    BaseRequestConfigHeadersOptions,
    BaseRequestConfig,
    APIError,
    Methods,
} from './APIService.types';
import { ErrorsCode } from './errors-code';
import { Routes } from '../../routes';
import { LocalStorageService } from '../LocalStorageService';

export class APIService {
    private API_URL!: string;
    private accessToken: string | null = null;
    private refreshToken: string | null = null;
    private queuedRequests: Array<APIQueuedRequest> = [];
    private isRefreshing = false;
    private hasFailedToRefresh = false;

    // modules
    readonly authentication!: AuthenticationRequest;
    readonly users!: UsersRequest;
    readonly subscribers!: SubscriberRequests;

    // ********************************************************************************
    // Constructor
    // ********************************************************************************

    constructor(API_URL: string) {
        this.API_URL = API_URL;
        this.authentication = new AuthenticationRequest(this.getRequestConfig('/auth'));
        this.users = new UsersRequest(this.getRequestConfig('/users'));
        this.subscribers = new SubscriberRequests(this.getRequestConfig('/subscribers'));

        const credentials = LocalStorageService.getCredentials();
        this.accessToken = credentials.accessToken;
        this.refreshToken = credentials.refreshToken;
    }

    // ********************************************************************************
    // Initialization
    // ********************************************************************************

    // ********************************************************************************
    // Auth
    // ********************************************************************************

    public isAuthenticated() {
        return this.accessToken != null && this.refreshToken != null;
    }

    public setCredentials(accessToken: string, refreshToken: string) {
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
        LocalStorageService.saveCredentials(accessToken, refreshToken);
    }

    public removeCredentials() {
        this.accessToken = null;
        this.refreshToken = null;
        LocalStorageService.removeCredentials();
    }

    // ********************************************************************************
    // Helpers
    // ********************************************************************************
    /**
     * Get API URL
     * @returns {string}
     */
    private getAPI_URL(): string {
        return this.API_URL;
    }

    /**
     * Get request headers
     * @param {boolean} [isAuthenticated = false]
     * @param {BaseRequestConfigHeadersOptions} [headerOptions]
     * @returns {APIHeader}
     */
    private getRequestHeaders(
        isAuthenticated: boolean = false,
        headerOptions?: BaseRequestConfigHeadersOptions,
    ): APIHeader {
        // Initialize basic headers
        const headers = {
            'content-type':
                headerOptions?.useMultiPartFormData === true ? 'multipart/form-data' : 'application/json',
            'WNC-LOCALE': i18n.language,
        } as Partial<APIHeader>;

        // Add Authorization when required
        if (isAuthenticated === true) {
            if (this.accessToken == null) {
                throw new Error('Credentials required.');
            }
            headers.authorization = this.accessToken as string;
        }
        return headers as APIHeader;
    }

    /**
     * Get request config
     * @param {string} BASE_URL
     * @returns {BaseRequestConfig}
     */
    private getRequestConfig(BASE_URL: string): BaseRequestConfig {
        return {
            getAPI_URL: this.getAPI_URL.bind(this),
            BASE_URL: BASE_URL,
            getRequestHeaders: this.getRequestHeaders.bind(this),
            processRequest: this.processRequest.bind(this),
        };
    }

    /**
     * Process request
     * @param {AxiosRequestConfig} request
     * @param {boolean} isAuthenticated
     * @returns {Promise<T>}
     */
    private async processRequest<T>(request: AxiosRequestConfig, isAuthenticated: boolean): Promise<T> {
        if (this.API_URL == null) {
            throw new Error('API not initialized');
        }
        if (isAuthenticated === true) {
            if (this.accessToken == null) {
                this.removeCredentials();
                throw new Error('Credentials required');
            }
        }

        // If we are currently refreshing the access token, queue the request
        if (this.isRefreshing === true) {
            return this.queueRequest(request);
        } else {
            // Otherwise, execute the request
            return axios(request)
                .then((response) => response.data)
                .catch((err) => this.handleError(err));
        }
    }

    /**
     * When API Service is refreshing access token, queue request
     * @param {AxiosRequestConfig} request
     * @returns {Promise<T>}
     */
    private async queueRequest<T>(request: AxiosRequestConfig): Promise<T> {
        return new Promise((resolve, reject) => {
            this.queuedRequests.push({
                request: request,
                resolve: resolve,
                reject: reject,
            });
        });
    }

    /**
     * When API Service is done refreshing access token, dequeue request if there are any
     */
    private async dequeueRequest() {
        await Promise.all(
            this.queuedRequests.map(async (item) => {
                const updatedHeaders = this.getRequestHeaders(true, {
                    useMultiPartFormData: item.request.data instanceof FormData,
                });
                item.request.headers = updatedHeaders;
                try {
                    const response = await axios(item.request);
                    item.resolve(response.data);
                } catch (err) {
                    item.reject(err);
                }
            }),
        );
        this.queuedRequests = [];
    }

    /**
     * Handle error
     * @param {APIError} error
     * @returns {Promise<T> | null}
     */
    private handleError<T>(error: APIError): Promise<T> | null {
        const errorStatus = error.response.status;
        const errorCode = error.response.data.error.code;

        switch (errorStatus) {
            case 400:
                throw error;
            case 401:
                if (errorCode === ErrorsCode.INVALID_ACCESS_TOKEN) {
                    if (this.refreshToken == null || this.hasFailedToRefresh) {
                        // We do not have refresh token or we already failed to refresh
                        this.removeCredentials();
                        throw error;
                    }

                    // We can try to refresh or/and queue the request
                    if (this.isRefreshing === false) {
                        this.isRefreshing = true;
                        this.refreshTokenHandler();
                    }

                    const { method, url, params, data } = error.config!;

                    const request = {
                        method: method,
                        url: url,
                        headers: this.getRequestHeaders(true),
                        params: params,
                        data: data,
                    } as AxiosRequestConfig;

                    return this.queueRequest(request);
                } else {
                    throw error;
                }
            case 404:
                throw error;
            case 500:
                throw error;
            default:
                throw error;
        }
    }

    /**
     * Refresh token handler
     */
    private async refreshTokenHandler() {
        try {
            const response = await axios({
                method: Methods.POST,
                url: `${this.API_URL}/auth/token`,
                headers: this.getRequestHeaders(),
                data: {
                    refreshToken: this.refreshToken,
                },
            });
            this.hasFailedToRefresh = false;
            await this.setCredentials(response.data.tokens.accessToken, this.refreshToken!);
            this.dequeueRequest();
        } catch (err) {
            this.hasFailedToRefresh = true;
            this.removeCredentials();
            await Promise.all(
                this.queuedRequests.map((item) => {
                    item.reject();
                }),
            );
            this.queuedRequests = [];
            window.location.replace(`/${Routes.LOGIN}`);
        } finally {
            this.isRefreshing = false;
        }
    }
}
