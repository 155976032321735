import React, { forwardRef, InputHTMLAttributes } from 'react';
import {
    Container,
    TextArea,
    Input,
    InputInnerLabel,
    TrailingIconContainer,
    LeadingIconContainer,
} from './TextInput-styles';

export type TextInputProps = InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> & {
    trailingIcon?: React.ReactNode;
    leadingIcon?: React.ReactNode;
    label?: string;
    textarea?: boolean;
};

const TextInput = forwardRef<HTMLInputElement | HTMLTextAreaElement, TextInputProps>(
    ({ trailingIcon, leadingIcon, label, textarea = false, ...rest }: TextInputProps, ref) => {
        const renderInput = () => {
            if (textarea === true) {
                return <TextArea {...rest} ref={ref as React.ForwardedRef<HTMLTextAreaElement>} />;
            } else {
                return <Input {...rest} ref={ref as React.ForwardedRef<HTMLInputElement>} />;
            }
        };

        return (
            <Container className="input-container" textarea={textarea}>
                {leadingIcon != null && (
                    <LeadingIconContainer className="input-container__leading-icon">
                        {leadingIcon}
                    </LeadingIconContainer>
                )}
                {label != null && <InputInnerLabel>{label}:</InputInnerLabel>}
                {renderInput()}
                {trailingIcon && (
                    <TrailingIconContainer className="input-container__trailing-icon">
                        {trailingIcon}
                    </TrailingIconContainer>
                )}
            </Container>
        );
    },
);

export { TextInput };
