import React, { ButtonHTMLAttributes } from 'react';
import { Container } from './Button-styles';
import { Link } from 'react-router-dom';

export enum ButtonAppearance {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
}

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    children: React.ReactNode;
    appearance: ButtonAppearance;
    path?: string;
    externalPath?: string;
    leadingIcon?: React.ReactNode;
    isExpanded?: boolean;
    isLoading?: boolean;
};

const Button = ({ children, appearance, path, externalPath, isExpanded = false, ...rest }: ButtonProps) => {
    const renderButton = () => (
        <Container {...rest} appearance={appearance} isExpanded={isExpanded}>
            {children}
        </Container>
    );

    if (path != null) {
        return <Link to={path}>{renderButton()}</Link>;
    }

    return renderButton();
};

export { Button };
