import React, { createContext } from 'react';
import { FormikProps } from 'formik';
import { Container } from './Form-styles';
import { ObjectSchema } from 'yup';

type Props = FormContextType & {
    children: React.ReactNode;
    oneColumn?: boolean;
};

type FormContextType = {
    formik: FormikProps<any>;
    validationSchema?: ObjectSchema<{ [key: string]: any }>;
};

export const FormContext = createContext<FormContextType>(null!);

const Form = ({ children, formik, validationSchema, oneColumn }: Props) => {
    return (
        <FormContext.Provider
            value={{
                formik: formik,
                validationSchema: validationSchema,
            }}
        >
            <Container onSubmit={formik.handleSubmit} oneColumn={oneColumn} noValidate>
                {children}
            </Container>
        </FormContext.Provider>
    );
};

export { Form };
