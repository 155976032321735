import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
    background: ${({ theme }) => theme.colors.primary.main};
    padding: ${({ theme }) => `${theme.box.spacing.xl} ${theme.box.spacing.xxl}`};
    display: grid;
    grid-template-columns: 2rem minmax(2rem, 7rem) 1fr minmax(2rem, 7rem) 2rem;

    @media (max-width: 1100px) {
        grid-template-columns: 2rem 0px 1fr 0px 2rem;
    }
`;

export const Content = styled.div`
    grid-column: 3 / 4;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: max-content;

    @media (max-width: 1100px) {
        grid-template-columns: repeat(2, 1fr);
    }

    ${({ theme }) => theme.breakpoints.midsize} {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${({ theme }) => theme.box.spacing.lg};
    }
`;

export const Section = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 1100px) {
        &.social {
            grid-column: 2 /3;
            grid-row: 2/3;
        }
    }

    ${({ theme }) => theme.breakpoints.midsize} {
        &.newsletter {
            width: 100%;
        }
    }
`;

export const SectionTitle = styled.h4`
    color: ${({ theme }) => theme.colors.text.light};
    font-size: ${({ theme }) => theme.font.size.lg};
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
`;

export const Navigation = styled.div`
    display: flex;
    flex-direction: column;
`;

export const NavigationItem = styled(Link)`
    margin-bottom: ${({ theme }) => theme.box.spacing.md};
    color: ${({ theme }) => theme.colors.text.secondary};

    &:hover {
        color: ${({ theme }) => theme.colors.text.light};
    }
`;

export const Icons = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.box.spacing.lg};

    svg {
        font-size: ${({ theme }) => theme.font.size.xl};
        color: ${({ theme }) => theme.colors.text.secondary};

        &:hover {
            color: ${({ theme }) => theme.colors.text.light};
        }
    }

    ${({ theme }) => theme.breakpoints.mobile} {
        align-self: center;
    }
`;

export const Icon = styled.a``;

export const NewsLetter = styled.div`
    display: flex;

    button {
        height: 100%;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    > *:first-child {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 1.6rem;
        border-bottom-left-radius: 1.6rem;
    }
`;

export const Footer = styled.div`
    grid-row: 2 /3;
    grid-column: 1 / -1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: ${({ theme }) => theme.colors.text.secondary};
    font-size: ${({ theme }) => theme.font.size.sm};

    @media (max-width: 1100px) {
        grid-row: 3/4;
        grid-column: 1 / -1;
        justify-content: center;
        padding-top: ${({ theme }) => theme.box.spacing.xxl};
    }
`;
