import { createContext, useEffect, useMemo, useState } from 'react';
import { User } from './types';
import { authenticationBusiness } from './business';

type AppContextType = {
    user?: User;
    isAuthenticated: boolean;
    isInitialized: boolean;
    login: (payload: { email: string; password: string }) => Promise<void>;
    logout: () => void;
};

export const AppContext = createContext<AppContextType>(null!);

export const useApp = (): AppContextType => {
    const [user, setUser] = useState<undefined | User>(undefined);
    const [isInitialized, setIsInitialized] = useState(false);
    const isAuthenticated = useMemo(() => user != null, [user]);

    useEffect(() => {
        initializeApp();
    }, []);

    /**
     * Initialize app
     */
    const initializeApp = async () => {
        try {
            const response = await authenticationBusiness.initialize();
            setUser(response);
        } catch (err) {
            // handle error
        } finally {
            setIsInitialized(true);
        }
    };

    /**
     * Login
     * @param payload
     */
    const login = async (payload: { email: string; password: string }) => {
        const loggedInUser = await authenticationBusiness.login(payload);
        setUser(loggedInUser);
    };

    const logout = () => {
        authenticationBusiness.logout();
        setUser(undefined);
    };

    return {
        user,
        isAuthenticated,
        isInitialized,
        login,
        logout,
    };
};
