import styled from 'styled-components';

export const Container = styled.div`
    min-height: 100vh;
    padding: 7vh 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Title = styled.h1`
    font-size: 5rem;
    text-align: center;
    line-height: 1.2;
    max-width: 60rem;
    margin-bottom: ${({ theme }) => theme.box.spacing.lg};

    ${({ theme }) => theme.breakpoints.midsize} {
        font-size: 4rem;
    }
`;

export const Text = styled.div`
    font-size: ${({ theme }) => theme.font.size.lg};
    text-align: center;
    max-width: 50rem;
    line-height: 1.4;
    margin-bottom: ${({ theme }) => theme.box.spacing.xxl};
`;

export const FormContainer = styled.div`
    width: 100%;
    max-width: 60rem;

    button {
        text-transform: uppercase !important;
    }
`;

export const CheckBoxContainer = styled.div`
    display: flex;
    align-items: center;
    grid-column: 1/-1;

    ${({ theme }) => theme.breakpoints.mobile} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
        grid-gap: ${({ theme }) => theme.box.spacing.md};

        & > *:first-child {
            grid-column: 1/ -1;
        }
    }
`;

export const CheckBoxText = styled.div`
    margin-right: ${({ theme }) => theme.box.spacing.lg};
`;

export const CheckBoxChoice = styled.div`
    margin-right: ${({ theme }) => theme.box.spacing.lg};

    ${({ theme }) => theme.breakpoints.mobile} {
        margin-right: unset;
    }
`;

export const SuccessMessage = styled.div`
    font-size: ${({ theme }) => theme.font.size.xxl};
    color: ${({ theme }) => theme.colors.primary.main};
    font-weight: ${({ theme }) => theme.font.weight.bold};
`;
