import React from 'react';
import { Button, ButtonAppearance, ButtonProps } from '../Button';

const SecondaryButton = ({ children, ...rest }: Omit<ButtonProps, 'appearance'>) => {
    return (
        <Button {...rest} appearance={ButtonAppearance.SECONDARY}>
            {children}
        </Button>
    );
};

export { SecondaryButton };
