import styled from 'styled-components';
import { TableColumnConfig, TableStyles } from './Table-types';

export const Container = styled.div<{
    columns: Array<TableColumnConfig<any>>;
    tableStyles: TableStyles;
    isLoading?: boolean;
    isEmpty?: boolean;
}>`
    width: 100%;
    height: 100%;
    border-radius: 3px;
    overflow-x: auto;

    ${({ isLoading, isEmpty }) => {
        if (isLoading === true || (isLoading === false && isEmpty === true)) {
            return {
                overflowX: 'hidden',
            };
        }
    }}
`;

export const Content = styled.div<{
    columns: Array<TableColumnConfig<any>>;
    tableStyles: TableStyles;
    isLoading?: boolean;
    isEmpty?: boolean;
}>`
    width: 100%;
    position: relative;
    display: grid;
    align-items: center;

    ${({ columns, tableStyles }) => {
        return {
            gridTemplateRows: `${tableStyles.header.height}px`,
            gridAutoRows: `minmax(${tableStyles.row.height}px, max-content)`,
            gridTemplateColumns: columns.map((column) => column.width).join(' '),
            minWidth: tableStyles.table.minWidth,
        };
    }}

    ${({ isLoading, isEmpty }) => {
        if (isLoading === true || (isLoading === false && isEmpty === true)) {
            return {
                height: '40rem',
            };
        }
    }}
`;

export const HeaderCell = styled.div<{ column: TableColumnConfig<any> }>`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 ${({ theme }) => theme.box.spacing.md};
    background: ${({ theme }) => theme.colors.background.secondary};
    color: ${({ theme }) => theme.colors.text.main};
    font-size: ${({ theme }) => theme.font.size.md};
    user-select: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    border-top: 1px solid ${({ theme }) => theme.colors.text.tertiary};

    ${({ column }) => {
        if (column.isCentered === true) {
            return {
                justifyContent: 'center',
            };
        }
    }}
`;

export const HeaderCellLabel = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const HeaderCellLabelText = styled.div`
    margin-right: ${({ theme }) => theme.box.spacing.xs};
`;

export const HeaderCellIcon = styled.div`
    width: ${({ theme }) => theme.font.size.sm};
    height: ${({ theme }) => theme.font.size.sm};
`;

export const BodyCell = styled.div<{
    'data-row': number;
    isClickable?: boolean;
    column: TableColumnConfig<any>;
    tableStyles: TableStyles;
}>`
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 0 ${({ theme }) => theme.box.spacing.md};
    background: ${({ theme }) => theme.colors.background.main};
    font-size: ${({ theme }) => theme.font.size.md};
    border-bottom: 1px solid ${({ theme }) => theme.colors.text.tertiary};

    &[data-hovered='true'] {
        background: ${({ theme }) => theme.colors.background.secondary};
    }

    ${({ column }) => {
        if (column.isCentered === true) {
            return {
                justifyContent: 'center',
            };
        }
    }}

    ${({ isClickable }) => {
        if (isClickable === true) {
            return {
                '*:hover': {
                    cursor: 'pointer',
                },
            };
        }
    }}
`;

export const BodyCellContent = styled.div<{ column: TableColumnConfig<any>; tableStyles: TableStyles }>`
    display: flex;
    align-items: center;
    width: 100%;
    height: ${({ tableStyles }) => `${tableStyles.row.height}px`};
    min-height: ${({ tableStyles }) => `${tableStyles.row.height}px`};
    line-height: 1.4;

    ${({ column }) => {
        if (column.isCentered === true) {
            return {
                justifyContent: 'center',
            };
        }
    }}

    ${({ column }) => {
        if (column.isExpanded === true) {
            return {
                height: 'max-content',
            };
        }
    }}
`;

export const BodyCellContentTruncated = styled.div<{ column: TableColumnConfig<any> }>`
    display: block;
    height: max-content;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: ${({ column }) => column.maxWidth};
`;

export const TablePlaceHolder = styled.div<{ tableStyles: TableStyles; width?: number }>`
    width: ${({ width }) => `${width}px`};
    height: 30rem;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 1 / -1;
    color: ${({ theme }) => theme.colors.text.main};
    border-bottom: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    background-color: ${({ theme }) => theme.colors.background.main};
`;

export const TableLoadingLabel = styled.div`
    margin-left: ${({ theme }) => theme.box.spacing.lg};
    font-size: ${({ theme }) => theme.font.size.sm};
`;

export const TableEmptyLabel = styled.div`
    background: ${({ theme }) => theme.colors.background.main};
    padding: ${({ theme }) => `${theme.box.spacing.md} ${theme.box.spacing.xl}`};
    border: 1px solid ${({ theme }) => theme.colors.text.tertiary};
    font-size: ${({ theme }) => theme.font.size.sm};
    color: ${({ theme }) => theme.colors.text.secondary};
`;

export const TableBodyRowActionContainer = styled.div`
    position: relative;
`;

export const TableBodyRowActionContent = styled.div`
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.text.secondary};
    :hover {
        background: ${({ theme }) => theme.colors.background.secondary};
    }
`;

export const TableBodyRowActionList = styled.div<{ positionListAtTop?: boolean }>`
    position: absolute;
    right: 0;
    width: max-content;
    min-width: 10rem;
    z-index: 2;
    background: ${({ theme }) => theme.colors.background.main};
    border-radius: 5px;
    box-shadow: ${({ theme }) => theme.box.shadow.main};
    overflow: hidden;

    ${({ positionListAtTop }) => {
        if (positionListAtTop === true) {
            return {
                bottom: 'calc(100% + 5px)',
            };
        } else {
            return {
                top: 'calc(100% + 5px)',
            };
        }
    }}
`;

export const TableBodyRowActionListItem = styled.div`
    padding: ${({ theme }) => theme.box.spacing.sm};
    cursor: pointer;
    font-size: ${({ theme }) => theme.font.size.md};
    :hover {
        background-color: ${({ theme }) => theme.colors.background.main};
    }
`;
