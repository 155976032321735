import React from 'react';
import { HomePhone, HomeSection } from '../../components';
import {
    Container,
    ContentTitle,
    ContentTitleText,
    Content,
    ContentDescription,
    List,
    ListItem,
    ListIemTitle,
    ListItemText,
    ListItemIcon,
    ContentDescriptionText,
    Phone,
    PhoneContainer,
} from './HomeIntro-styles';
import { FiCheckCircle } from 'react-icons/fi';
import { IoWalletOutline } from 'react-icons/io5';
import { FaMedal } from 'react-icons/fa';
import { HomeSectionsDictionary } from '../../Home-types';
import { useWindowSize } from 'usehooks-ts';

import intro1 from '../../../../assets/intro1.png';
import hero1 from '../../../../assets/hero1.png';

const HomeIntro = () => {
    const windowSize = useWindowSize();

    const list = [
        {
            icon: <FiCheckCircle />,
            title: 'Des missions sans engagement',
            renderText: () => (
                <>
                    Notre application permet aux étudiants de trouver <b>facilement</b> des missions
                    rémunérées tout en gagnant de l'expérience
                </>
            ),
        },
        {
            icon: <IoWalletOutline />,
            title: 'Un service flexible et peu coûteux',
            renderText: () => (
                <>
                    Les employeurs accèdent à des <b>talents flexibles</b> pour des <b>tâches spécifiques</b>{' '}
                    sans les contraintes d'un recrutement classique
                </>
            ),
        },
        {
            icon: <FaMedal />,
            title: 'Une collaboration Win-Win',
            renderText: () => (
                <>
                    Les étudiants <b>boostent</b> leur CV et leur revenu, et les employeurs bénéficient d'une
                    aide&nbsp;
                    <b>rapide</b>, <b>économique</b>, et <b>adaptée</b> à leurs besoins
                </>
            ),
        },
    ];

    return (
        <HomeSection>
            <Container id={HomeSectionsDictionary.INTRO}>
                <PhoneContainer>
                    <Phone>
                        <HomePhone content={windowSize.width > 600 ? intro1 : hero1} />
                    </Phone>
                </PhoneContainer>

                <Content>
                    <ContentTitle>
                        <ContentTitleText>Wiinco, qu'est-ce</ContentTitleText>
                        <ContentTitleText>que c'est ?</ContentTitleText>
                    </ContentTitle>

                    <ContentDescription>
                        <ContentDescriptionText>
                            Wiinco est une application qui connecte étudiants et entrepreneurs, freelance, ou
                            petites entreprises pour des missions ponctuelles sans engagement.
                        </ContentDescriptionText>
                    </ContentDescription>

                    <List>
                        {list.map(({ icon, title, renderText }, index) => (
                            <ListItem key={index}>
                                <ListItemIcon>{icon}</ListItemIcon>
                                <ListIemTitle>{title}</ListIemTitle>
                                <ListItemText>{renderText()}</ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </Content>
            </Container>
        </HomeSection>
    );
};

export { HomeIntro };
