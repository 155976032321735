import BaseRequest from './_baseRequest';
import { BaseRequestConfig } from '../APIService.types';
import { Subscriber } from '../../../types';

class SubscriberRequests extends BaseRequest {
    constructor(config: BaseRequestConfig) {
        super(config);
    }

    async postSubscriber(payload: { firstName: string; lastName: string; email: string }): Promise<{}> {
        return this.post('/', false, payload);
    }

    async listSubscribers(): Promise<Array<Subscriber>> {
        return this.get('/', true).then((res: any) => res.subscribers);
    }
}

export { SubscriberRequests };
