import React, { useEffect } from 'react';
import { Container } from './App-styles';
import { AppRoutes } from './routes';
import { Header } from './elements';
import { AppContext, useApp } from './App-context';

const App = () => {
    const app = useApp();

    useEffect(() => {
        window.scroll(0, 0);
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
    }, []);

    return (
        <AppContext.Provider value={app}>
            <Container>
                {app.isInitialized && (
                    <>
                        <Header />
                        <AppRoutes />
                    </>
                )}
            </Container>
        </AppContext.Provider>
    );
};

export default App;
