import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container, Content } from './Dashboard-styles';
import { DashboardSidebar } from './components';

const DashboardPage = () => {
    return (
        <Container>
            <DashboardSidebar />
            <Content>
                <Outlet />
            </Content>
        </Container>
    );
};

export { DashboardPage };
