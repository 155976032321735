import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    left: 0;
    top: ${({ theme }) => `${theme.config.header.height.default}px`};
    width: 30rem;
    height: ${({ theme }) => `calc(100vh - ${theme.config.header.height.default}px)`};
    background: ${({ theme }) => theme.colors.background.main};
    border-right: 1px solid ${({ theme }) => theme.colors.text.secondary};
`;

export const Navigation = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const NavigationItem = styled(NavLink)`
    width: 100%;
    padding: ${({ theme }) => theme.box.spacing.md};
    color: ${({ theme }) => theme.colors.text.main} !important;
    font-size: ${({ theme }) => theme.font.size.md};
    display: flex;
    align-items: center;

    svg {
        margin-right: ${({ theme }) => theme.box.spacing.sm};
        font-size: ${({ theme }) => theme.font.size.xl};
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.background.secondary};
    }

    &.active {
        background-color: ${({ theme }) => theme.colors.background.secondary};
    }
`;
