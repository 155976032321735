import React, { useState } from 'react';
import { Container, Logo, LogoContainer } from './HeaderPublicMobile-styles';
import logo from '../../../../assets/logo-dark-small.png';
import { HeaderPublicMobileHamburger, HeaderPublicMobileMenu } from './components';

const HeaderPublicMobile = () => {
    const [isOpened, setIsOpened] = useState(false);

    const toggleMenu = (state?: boolean) => {
        if (state != null) {
            setIsOpened(state);
        } else {
            setIsOpened((prev) => !prev);
        }
    };

    return (
        <Container>
            <LogoContainer to={'/'}>
                <Logo src={logo} alt="Wiinco logo" />
            </LogoContainer>
            <HeaderPublicMobileHamburger isOpened={isOpened} toggleMenu={toggleMenu} />
            <HeaderPublicMobileMenu isOpened={isOpened} toggleMenu={toggleMenu} />
        </Container>
    );
};

export { HeaderPublicMobile };
