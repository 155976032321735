import { useState, useEffect, Dispatch, SetStateAction } from 'react';

export type Promised<T> = {
    data: T;
    isLoading: boolean;
    refresh: () => Promise<void>;
    update: (obj: T) => void;
};

type PromiseReturnTypes<T extends (() => Promise<any>)[]> = {
    [K in keyof T]: {
        data: T[K] extends () => Promise<infer R> ? R : never;
        isLoading: boolean;
        refresh: () => Promise<void>;
        update: (obj: T[K] extends () => Promise<infer R> ? R : never) => void;
    };
};

export const usePromises = <T extends (() => Promise<any>)[]>(
    ...promises: T
): [
    {
        isInitializing: boolean;
        error: boolean | string | null;
        setError: Dispatch<SetStateAction<string | boolean | null>>;
        clearError: () => void;
        isExecutingPromise: boolean;
        executePromise: <U>(promise: () => Promise<U>) => Promise<U>;
    },
    PromiseReturnTypes<T>,
] => {
    const [isInitializing, setIsInitializing] = useState(true);
    const [isExecutingPromise, setIsExecutingPromise] = useState(false);
    const [error, setError] = useState<boolean | string | null>(false);
    const [results, setResults] = useState<Array<{ data: any; isLoading: boolean }>>(
        promises.map(() => ({ data: null, isLoading: true })),
    );

    useEffect(() => {
        const fetchResults = async () => {
            try {
                const initialResults = await Promise.all(
                    promises.map(async (promise, index) => {
                        const value = await promise();
                        return {
                            data: value,
                            isLoading: false,
                            refresh: () => refreshResult(promise, index),
                            update: (updated: any) => updateResultsDataByIndex(updated, index),
                        };
                    }),
                );
                setResults(initialResults);
            } catch (err) {
                setError(true);
            } finally {
                setIsInitializing(false);
            }
        };
        fetchResults();
    }, []);

    const updateResultsDataByIndex = (updatedResult: any, index: number) => {
        setResults((prevResult) =>
            prevResult.map((prevResultItem, prevResultItemIndex) =>
                prevResultItemIndex === index
                    ? {
                          ...prevResultItem,
                          data: updatedResult,
                      }
                    : prevResultItem,
            ),
        );
    };

    const updateResultsLoadingByIndex = (isLoading: boolean, index: number) => {
        setResults((prevResult) =>
            prevResult.map((prevResultItem, prevResultItemIndex) =>
                prevResultItemIndex === index
                    ? {
                          ...prevResultItem,
                          isLoading: isLoading,
                      }
                    : prevResultItem,
            ),
        );
    };

    const refreshResult = async (promise: () => Promise<void>, index: number) => {
        try {
            updateResultsLoadingByIndex(true, index);
            const value = await promise();
            updateResultsDataByIndex(value, index);
        } catch (err) {
            setError(true);
        } finally {
            updateResultsLoadingByIndex(false, index);
        }
    };

    const executePromise = async <U>(promise: () => Promise<U>) => {
        if (isExecutingPromise === true) {
            return null as U;
        }

        let response = null as U;
        try {
            setIsExecutingPromise(true);
            response = await promise();
        } catch (err) {
            setError(true);
        } finally {
            setIsExecutingPromise(false);
        }
        return response;
    };

    return [
        {
            isInitializing: isInitializing,
            error: error,
            setError: setError,
            clearError: () => setError(false),
            isExecutingPromise: isExecutingPromise,
            executePromise: executePromise,
        },
        results as PromiseReturnTypes<T>,
    ];
};
