import React, { useEffect } from 'react';
import { Container } from './Home-styles';
import { HomeFooter, HomeHero, HomeIntro, HomeNewsLetter, HomePerks } from './sections';
import { HomeSectionsDictionary } from './Home-types';
import { useLocation } from 'react-router-dom';

const HomePage = () => {
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash.split('#')[1];
        const element = document.getElementById(hash);
        if (element != null) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);

    const goToNewsLetter = () => {
        const element = document.getElementById(HomeSectionsDictionary.NEWS_LETTER);
        element!.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <Container>
            <HomeHero goToNewsLetter={goToNewsLetter} />
            <HomeIntro />
            <HomePerks goToNewsLetter={goToNewsLetter} />
            <HomeNewsLetter />
            <HomeFooter />
        </Container>
    );
};

export { HomePage };
