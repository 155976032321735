import React from 'react';
import { Container, Line } from './HeaderPublicMobileHamburger-styles';

type Props = {
    isOpened: boolean;
    toggleMenu: (state?: boolean) => void;
};

const HeaderPublicMobileHamburger = ({ isOpened, toggleMenu }: Props) => {
    return (
        <Container isOpened={isOpened} onClick={() => toggleMenu()}>
            <Line />
            <Line />
            <Line />
        </Container>
    );
};

export { HeaderPublicMobileHamburger };
