import React, { useEffect, useRef, useState } from 'react';
import { Container, Content } from './HomeSection-styles';

type Props = {
    children: React.ReactNode;
    animationDelay?: number;
    backgroundColor?: string;
    renderBackground?: () => React.ReactNode;
};

const HomeSection = ({ children, backgroundColor, renderBackground, animationDelay }: Props) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(ref.current!); // Stop observing once visible
                }
            },
            {
                rootMargin: '0px 0px -20% 0px', // Adjust offset as needed
                threshold: 0.1, // Trigger when 10% of the element is visible
            },
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) observer.unobserve(ref.current);
        };
    }, []);

    return (
        <Container backgroundColor={backgroundColor}>
            {typeof renderBackground === 'function' && renderBackground()}
            <Content ref={ref} isVisible={isVisible} animationDelay={animationDelay}>
                {children}
            </Content>
        </Container>
    );
};

export { HomeSection };
